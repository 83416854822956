//////画像切り替え
$(function () {
  var width = $(window).width();
  if( width < 667 ){
    $("img").each(function(){
      $(this).attr("src", $(this).attr("src").replace("_pc","_sp"));
    })
  }
});
//////ナビゲーション
$(function(){
  $('.menu_trigger').on('click',function(){
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
    $('.header_contents').addClass('active');
    $("header").removeClass("active_s");
    $('.h_nav_area').slideUp();
  });
});
$('.header_nav_contents a').on('click', function(){
        if (window.innerWidth <= 900) {
            $('.menu_trigger').click();
        }
    });	
$(window).on('resize', function() {
	if( 'none' == $('.header_nav_contents').css('display') ){
		$('.header_nav_contents').attr('style','');
	}
});
//////ヘッダー変更
$(window).scroll(function(){
  if ($(window).scrollTop() > 50) {
    $('.header').addClass('header_fixed');
  } else {
    $('.header').removeClass('header_fixed');
  }
});
//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
});
//////スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 1500 ;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});
//////スムーススクロールIE
if(navigator.userAgent.match(/(msie|MSIE) 10/i) || navigator.userAgent.match(/(T|t)rident\/7\./) || navigator.userAgent.match(/Edge\/\d+\.\d+/)) {
   $('body').on("mousewheel", function () {
     if(event.preventDefault){
        event.preventDefault();
     }else{
        event.returnValue=false;
     }
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
//////リンク
$(document).ready(function(){
  $(".link").click(function(){
    if($(this).find("a").attr("target")=="_blank"){
    window.open($(this).find("a").attr("href"), '_blank');
    }else{
    window.location=$(this).find("a").attr("href");
    }
    return false;
  });
})
//////telリンク
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}